button {
    background-color: inherit;
}

// $table-cell-padding-y:        .5rem;
// $table-cell-padding-x:        1.5rem;
// $table-cell-padding-y-sm:     .25rem;
// $table-cell-padding-x-sm:     .25rem;

// modal overrides

.modal-dialog.modal-fullscreen-url-viewer-down {
    width: 90vw;
    max-width: none;
    height: 100%;
    margin: auto;
    padding-top: 30px;

    .modal-body {
      padding: 1rem;
    }
}

.modal-dialog {
  //max-width: 600px;
  //margin: 8rem auto;
}

.suggestion-html-content > a {
  color: #067272;
  font-weight: 500;
  cursor: auto;
}


.modal-content {
  border-radius: 10px;
  padding: 1rem;
}

@media (min-width: 800px)
{
  .modal-dialog {
    max-width: fit-content;
    //max-width: 600px;
    //margin: 1.75rem auto;
  }
}

.modal-body {
  //padding: 2.4rem 2.4rem 2.4rem 2.4rem;
  .h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
    margin-bottom: 1.25rem;
    color: #022B2A;
  }
}



// .myCustomModalClass {
//     position: fixed;
//     top: 20px;
//     right: 20px;
//     bottom: 20px;
//     left: 20px;
//     z-index: 1060;
//     display: none;
//     overflow: hidden;
//     -webkit-overflow-scrolling: touch;
//     outline: 0;
// }

// .modal.modal-fullscreen {
//     /* Maximize the main wrappers on the screen */
//     /* Make the parent wrapper of the modal box a full-width block */
//     /* Remove borders and effects on the content */
//     /**
//        * /!\ By using this feature, you force the header and footer to be placed
//        * in an absolute position. You must handle by yourself the margin of the
//        * content.
//        */
//   }
//   .modal.modal-fullscreen .modal-dialog,
//   .modal.modal-fullscreen .modal-content {
//     bottom: 0;
//     left: 0;
//     position: absolute;
//     right: 0;
//     top: 0;
//   }
//   .modal.modal-fullscreen .modal-dialog {
//     margin: 0;
//     width: 100%;
//     animation-duration:0.6s;
//   }
//   .modal.modal-fullscreen .modal-content {
//     border: none;
//     -moz-border-radius: 0;
//     border-radius: 0;
//     -webkit-box-shadow: inherit;
//     -moz-box-shadow: inherit;
//     -o-box-shadow: inherit;
//     box-shadow: inherit;
//     /* change bg color below */
//    /* background:#1abc9c; */
//   }
//   .modal.modal-fullscreen.force-fullscreen {
//     /* Remove the padding inside the body */
//   }
//   .modal.modal-fullscreen.force-fullscreen .modal-body {
//     padding: 0;
//   }
//   .modal.modal-fullscreen.force-fullscreen .modal-header,
//   .modal.modal-fullscreen.force-fullscreen .modal-footer {
//     position: absolute;
//     bottom: 0;
//   }
//   .modal.modal-fullscreen.force-fullscreen .modal-header {
//     top: 0;
//   }
  
  /* The styles above for the modal-body, footer, etc aren't being applied so I tried the ones below and they work */
  .modal-body {
    height: 100%;
  }
  
  // .modal-footer {
  //   width: 100%;
  //   position: absolute;
  //   bottom: 0;
  //   background-color: #FFF;
  // }
  