@import './colors.scss';
@import './mixins.scss';

// Styles for main view container
.main {
    /* previous syntax */
    -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -ms-box-flex: 1;
            box-flex: 1;

    /* current syntax */
    -webkit-flex: 1;
        -moz-flex: 1;
        -ms-flex: 1;
            flex: 1;

    height: 100%;
    width: 100%;
    margin: 0;
    //padding: 10px;
    //padding: 10px 6% 10px 6%;
    overflow-y: auto;
}

.main-header-container {
    //font-size: 20px;
    margin-bottom: 20px;
    // margin-top: 20px;
    //font-family: 'Alice', serif;
    @include hflex;
    .main-header {
        @include hflex;
        flex-wrap: wrap;
        .main-header-title {
            font-size: 1.25rem;
            flex-basis: 100%;
            font-weight: 500;
            //text-shadow: 2px 2px $lightgray;
        }
        .main-header-icon {
            color: $andrena-blue;
            font-size: 28px;
            margin-right: 10px;
            align-self: center;
            text-shadow: 2px 2px $lightgray;
        }
    }
}

.main-aside-open {
    padding-right: calc(320px + 0px);
}

.main-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
}

.main-loading-container {
    //Todo: add styling for loading 
}

.sunken {
    box-shadow: 
        1px 3px 5px -1px rgb(0 0 0 / 20%) inset, 
        1px 6px 10px 0 rgb(0 0 0 / 14%) inset, 
        1px 1px 18px 0 rgb(0 0 0 / 12%) inset;
}
