/* You can add global styles to this file, and also import other style files */
@import "./scss/bootstrap-5.0.0-dist/css/bootstrap.css";

// @import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
// @import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "ag-grid-community/styles//ag-grid.css";
@import "ag-grid-community/styles//ag-theme-alpine.css";

@import url('https://fonts.googleapis.com/css?family=Courgette|Gloria+Hallelujah');
@import url('https://fonts.googleapis.com/css2?family=Alice&family=New+Tegomin&display=swap');

@import "./scss/index.scss";

html {
    height: 100%;
    font-size: 14px;
}

body { 
    height: 100%;   
    box-sizing: border-box;
    margin: 0px;  /*removes default style*/
    display: flex;  /*enables flex content for its children*/
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
    color: #172b4d;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-text-decoration-skip-ink: auto;
    text-decoration-skip-ink: auto;
    background-color: #fff;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.42857;
}

html, body, .viewport, app-root {
  width: 100%;
  height: 100%;
  margin: 0;
  display: flex;
}

// html::-webkit-scrollbar-track {
//   background-color: darkblue;
// }

// body::-webkit-scrollbar-track {
//   background-color: darkblue;
// }

// body::-webkit-scrollbar-track
// {
// 	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
// 	border-radius: 10px;
// 	background-color: #F5F5F5;
// }

// body::-webkit-scrollbar
// {
// 	width: 12px;
// 	background-color: #F5F5F5;
// }

// body::-webkit-scrollbar-thumb
// {
// 	border-radius: 10px;
// 	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
// 	background-color: #D62929;
// }

html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif;}

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';
