@mixin fbox {
    flex:1;
    display: flex;
}
  
@mixin vflex {
    /* current syntax */
    display: -webkit-flex;
    display:    -moz-flex;
    display:     -ms-flex;
    display:         flex;

    -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
}

@mixin hflex {
    /* current syntax */
    display: -webkit-flex;
    display:    -moz-flex;
    display:     -ms-flex;
    display:         flex;

    -webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
}

@mixin overflow-hidden {
    overflow: hidden;
    white-space: nowrap;
}

@mixin shadow-gray {
    box-shadow: 2px 2px 0px 0px $lightgray,
}

@mixin center-margin {
    margin-right: auto;
    margin-left: auto;
}

@mixin shadow1 {
    box-shadow: 2px 2px 0px 0px $lightgray,
        0px 0px 6px 0px $lightgray;
}

@mixin border1 {
    border: 1px solid $lightgray;
}

@mixin rounded-border1 {
    border: 1px solid $lightgray;
    border-radius: 8px;
}

@mixin space-between {
    /* previous syntax */
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-box-pack: justify;
        box-pack: justify;

    /* current syntax */
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
        justify-content: space-between;
}

@mixin flex-left {
    justify-self: flex-start;
    margin-right: auto;
}

@mixin flex-right {
    justify-self: flex-end;
    margin-left: auto;
}

@mixin sunken {
    box-shadow: 
        1px 3px 5px -1px rgb(0 0 0 / 20%) inset, 
        1px 6px 10px 0 rgb(0 0 0 / 14%) inset, 
        1px 1px 18px 0 rgb(0 0 0 / 12%) inset;
}

@mixin hive-splash1 {
    content: "";
    background-image: url("/assets/bg1.jpg");
    opacity: 1;
    background-repeat: no-repeat;
    background-size: cover; 
}

.fbox {
  flex:1;
  display: flex;
}

.vflex {
  /* current syntax */
  display: -webkit-flex;
  display:    -moz-flex;
  display:     -ms-flex;
  display:         flex;

  -webkit-flex-direction: column;
     -moz-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}

.hflex {
  /* current syntax */
  display: -webkit-flex;
  display:    -moz-flex;
  display:     -ms-flex;
  display:         flex;

  -webkit-flex-direction: row;
     -moz-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
}

.flex-center {
    //display: inline-flex;
    align-items: center;
    justify-content: center;
}

.shadow-gray {
    border: 1px solid $lightgray;      
    box-shadow: 2px 2px 5px 1px $lightgray;
}

.space-between {
    /* previous syntax */
    -webkit-box-pack: justify;
        -moz-box-pack: justify;
        -ms-box-pack: justify;
            box-pack: justify;

    /* current syntax */
    -webkit-justify-content: space-between;
        -moz-justify-content: space-between;
        -ms-justify-content: space-between;
            justify-content: space-between;
}

.flex-left {
    justify-self: flex-start;
    margin-right: auto;
}

.flex-right {
    justify-self: flex-end;
    margin-left: auto;
}