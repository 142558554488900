
// Form styles
.light-style__logo-container img {
    width: 170px;
    display: flex;
    /* padding: 0px; */
    align-items: center;
    background: none;
    border: 0px;
    border-radius: 3px;
    color: inherit;
    cursor: pointer;
    margin: 20px;
}

.light-style__floating-form-container {
    width: 350px;
    margin: 20px auto;
    background-color: inherit;
    border-radius: 15px;
    padding: 4px;
}

.light-style__form-group {
    margin-top: 20px;

    label {
        display: block;
        margin-bottom: 10px;
    }

    input {
        // width: 100%;
        // padding: 10px;
        // border-radius: 5px;
        // border: 1px solid lightgray;
        min-width: 0;
        width: 100%;
        height: 100%;
        padding: 10px;
        border: 1px solid #c4c7cf;
        border-radius: 6px;
        box-sizing: border-box;
        color:  #191b23;
        font-size: inherit;
        text-overflow: ellipsis;
        font-family: inherit;
    }
}

.light-style__form-divider{
    position: relative;
    margin-top: 14px;
    margin-bottom: 16px;
    text-align: center;
    border-top: 1px solid #d1d4db;
    .divider-inner {
        position: absolute;
        top: -10px;
        left: 0;
        right: 0;
        .divider-text {
            background-color: #fff;
            padding: 0 25px;
            font-size: 14px;
        }
    }
}

// End Form styles

// Link styles
.light-style__quiet-link {
    display: inline-block;
    font-family: inherit;
    color: #006dca;
    line-height: normal;
    position: relative;
    cursor: pointer;
    -webkit-text-decoration: none;
    text-decoration: none;
    border: 0;
    padding: 0;
    margin: 0;
    box-shadow: none;
    -webkit-tap-highlight-color: transparent;
    outline: 0;
    background: 0 0;
    transition: color .15s ease-in-out;
}

// End Link styles

// Button styles
.light-style__button_form-submit {
    background-color: rgba(205, 227, 227, 0.8);
    width: 100%;
    border: 0;
    //border: 1px solid rgba(205, 227, 227, 0.8);
    position: relative;
    margin-bottom: 12px;
    height: 40px;
    border-radius: 6px;
    font-size: 16px;
}

.light-style__button_third-party-auth {
    background-color: #fff;
    width: 100%;
    border: 1px solid #c4c7cf;
    position: relative;
    margin-bottom: 12px;
    height: 40px;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;

    img {
        margin-top: -4px;
    }
}

.button_full-width {
    width: 100%;
}

// End button styles

main.light-style__page-container{

    padding: 20px 40px;
    max-width: 1080px;

    
    h5, .h5 {
        font-size: 1.625rem;
    }
    
    .page-header {
        margin: 0;
    }
    
    section {
        margin-bottom: 30px;
    }

    .section-discription {
        font-size: 1em;
        color: lightslategray;
        margin-bottom: 20px;
    }
}
