$gray: gray !default;
$silver: #C3CDE6 !default;
$gold: gold !default;
$brown: brown !default;
$andrena-blue: rgba(28, 129, 158, 0.8);
$black: black !default;
$lightgray: lightgray !default;
$darkgray: darkgray !default;
$white: white !default;

.gray {
    color: gray;
}
.silver {
    color:#C3CDE6;
}
.gold {
    color: gold;
}
.brown {
    color: brown;
}
  